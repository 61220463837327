var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactionvolumes"},[_c('div',{staticClass:"chart_label"},[_c('h2',{attrs:{"id":"transactionvolumes_l"}},[_vm._v(_vm._s(_vm.$t('transaction_volumes.label')))]),_c('div',{staticClass:"chart_num"},[_vm._v(_vm._s(_vm.formatNumberByLocale(_vm.num, 0)))]),_c('ChartResolutions',{attrs:{"value":_vm.resolution,"labeled-by":"transactionvolumes_l"},on:{"change":_vm.onChartResolutionsChange}})],1),_c('f-lightweight-charts',{attrs:{"series":_vm.txVolumeSeries,"series-type":"area","__time-to-timestamp":"","fit-content":true,"height":212,"price-scale-min":0,"price-scale-max":'auto',"options":{
            handleScroll: false,
            handleScale: false,
            // timeScale: { visible: false },
            // timeScale: { timeVisible: true },
        },"series-options":{
            priceLineVisible: false,
            priceFormat: { type: 'volume', precision: 2, minMove: 1 },
            priceScale: {
                scaleMargins: {
                    top: 0.05,
                    bottom: 0.02,
                },
            },
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }