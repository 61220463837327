<template>
    <ul class="socialmedialinks no-markers" :class="{ 'socialmedialinks_simple': simple }">
        <li>
            <a
                href="https://t.me/SonicAnnouncements"
                title="Sonic on Telegram"
                aria-label="Sonic on Telegram"
                target="_blank"
                :class="itemClass"
            >
                <icon data="@/assets/svg/social-media2/telegram.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://x.com/0xSonicLabs"
                title="Sonic on X"
                aria-label="Sonic on X"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/x.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://github.com/Fantom-Foundation"
                title="Sonic on Github"
                aria-label="Sonic on Github"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/github.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://discord.gg/3Ynr2QDSnB"
                title="Sonic on Discord"
                aria-label="Sonic on Discord"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/discord.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://www.reddit.com/r/0xSonic/"
                title="Sonic on Reddit"
                aria-label="Sonic on Reddit"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/reddit.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://www.instagram.com/0xSonic"
                title="Sonic on Instagram"
                aria-label="Sonic on Instagram"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/instagram.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://www.youtube.com/@0xSonic"
                title="Sonic on Youtube"
                aria-label="Sonic on Youtube"
                target="_blank"
                :class="itemClass"
                data-testcode="linkgroup_link"
            >
                <icon data="@/assets/svg/social-media2/youtube.svg" width="24" height="24"></icon>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        simple: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        itemClass() {
            return !this.simple ? 'btn secondary same-size round' : '';
        }
    }
}
</script>

<style lang="scss">
.socialmedialinks {
    display: flex;
    //justify-content: space-between;
    gap: 8px;
    padding: 0;

    &_label {
        font-size: var(--f-font-size-5);
    }

    a:visited,
    a:link {
        color: var(--f-doc-color) !important;
    }

    a.btn {
        width: 64px;
        height: 64px;
    }

    &_simple {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
        }
    }
}
</style>
