<template>
    <div class="dailyfees">
        <div class="chart_label">
            <h2 id="dailyfees_l">{{ $t('daily_fees.label') }}</h2>
            <div class="chart_num">{{ formatNumberByLocale(num, 0) }}</div>
            <ChartResolutions :value="resolution" labeled-by="dailyfees_l" @change="onChartResolutionsChange" />
        </div>
        <FLightweightCharts
            :series="dailyBlocksSeries"
            series-type="area"
            :fit-content="true"
            :height="212"
            :price-scale-min="0"
            :price-scale-max="'auto'"
            :options="{
                handleScroll: false,
                handleScale: false,
                // timeScale: { visible: false },
                // timeScale: { timeVisible: true },
            }"
            :series-options="{
                priceLineVisible: false,
                priceFormat: { type: 'volume', precision: 2, minMove: 1 },
                priceScale: {
                    scaleMargins: {
                        top: 0.05,
                        bottom: 0.02,
                    },
                },
            }"
        />
    </div>
</template>

<script>
import ChartResolutions from "@/components/ChartResolutions.vue";
import FLightweightCharts from "@/components/core/FLightweightCharts/FLightweightCharts.vue";
import gql from "graphql-tag";
import {getISODateByTimecode} from "@/utils/time.js";
import {reverseSeries} from "@/utils/chart.js";
import {formatNumberByLocale} from "../filters";

export default {
    name: "DailyTxFees",

    components: {FLightweightCharts, ChartResolutions},

    props: {
        /** Default resolution */
        resolution: {
            type: String,
            default: '14d'
        }
    },

    data() {
        return {
            dailyBlocksSeries: [],
            num: 0
        }
    },

    mounted() {
        setTimeout(() => {
            this.loadDailyTxFees(getISODateByTimecode(this.resolution));
        }, 1550);
    },

    methods: {
        formatNumberByLocale,
        async loadDailyTxFees(from = null, to = null) {
            let dailyBlocks = await this.fetchDailyTxFees(from, to);

            dailyBlocks = dailyBlocks.map((item) => {
                return {
                    time: item.date.toString(),
                    value: item.feeFTM,
                };
            });

            this.dailyBlocksSeries = reverseSeries(dailyBlocks);

            this.num = parseInt(this.dailyBlocksSeries[this.dailyBlocksSeries.length - 1].value, 10);
        },

        async fetchDailyTxFees(from = null, to = null) {
            const data = await this.$apollo.query({
                query: gql`
                    query DailyFeeFlow($from:Time, $to:Time) {
                        dailyFeeFlow(from: $from, to: $to) {
                            date
                            feeFTM
                        }
                    }
                `,
                variables: {
                    from: from,
                    to: to,
                },
                fetchPolicy: 'network-only',
            });

            return data.data.dailyFeeFlow || [];
        },

        onChartResolutionsChange(value) {
            this.loadDailyTxFees(getISODateByTimecode(value));
        }
    }
}
</script>

<style scoped>

</style>
