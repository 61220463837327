<template>
    <div class="view-home">
        <div class="narrow-container">
<!--
            <div class="row">
                <div class="col-8 offset-2 col-10-lg offset-1-lg col-12-sm no-offset-sm">
                    <f-search-box></f-search-box>
                </div>
            </div>
-->
            <div class="view-home_header">
                <div>
                    <h1>{{ appConfig.name }}</h1>
                    <h2>Deploy your contracts and experience the incredible speed of Sonic.</h2>
<!--
                    <div>
                        <AddChainButton />
                    </div>
-->
                </div>
                <ChainStats
                    :blocks="chainState.currentBlockHeight"
                    :validators="chainState.numberOfValidators"
                    :accounts="chainState.numberOfAccounts"
                    :transactions="chainState.numberOfTransactions"
                    :transactions-per-second="transactionsPerSecond"
                    :transactions-per-day="transactionsPerDay"
                    :gas-per-second="gasPerSecond"
                    :time-to-finality="chainState.timeToFinality"
                    :time-to-block="chainState.timeToBlock"
                />
            </div>

<!--
            <div class="row row-2-cols-lg no-collapse equal-height">
                <div class="col">
                    <router-link :to="{name: 'blocks'}" class="no-effect">
                        <f-card class="home-block" hover>
                            <h2>{{ $t('view_home.blocks') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h2>
                            <div class="num">
                                <animated-number
                                    :value="chainState.blocks | formatHexToInt"
                                    :formatValue="formatNum"
                                    :duration="numAnimationDuration"
                                />
                            </div>
                        </f-card>
                    </router-link>
                </div>
                <div class="col">
                    <router-link :to="{name: 'staking'}" class="no-effect">
                        <f-card class="home-block" hover>
                            <h2>{{ $t('view_home.validators') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h2>
                            <div class="num">
                                <animated-number
                                    :value="chainState.validators | formatHexToInt"
                                    :formatValue="formatNum"
                                    :duration="numAnimationDuration"
                                />
                            </div>
                        </f-card>
                    </router-link>
                </div>
                <div class="col">
                    <f-card class="home-block">
                        <h2>{{ $t('view_home.accounts') }}</h2>
                        <div class="num">
                            <animated-number
                                :value="chainState.accounts | formatHexToInt"
                                :formatValue="formatNum"
                                :duration="numAnimationDuration"
                            />
                        </div>
                    </f-card>
                </div>
                <div class="col">
                    <router-link :to="{name: 'transactions'}" class="no-effect">
                        <f-card class="home-block" hover>
                            <h2>{{ $t('view_home.transactions') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h2>
                            <div class="num">
                                <animated-number
                                    :value="chainState.transactions | formatHexToInt"
                                    :formatValue="formatNum"
                                    :duration="numAnimationDuration"
                                />
                            </div>
                        </f-card>
                    </router-link>
                </div>
            </div>
-->

            <div v-if="showNetworkNodesMap" class="row mat-5">
                <div class="col">
                    <f-card class="half-padding">
                        <h2>
                            {{ $t('view_home.fantom_mainnet_nodes') }}
                            <f-info show-on-hover button-tooltip="" window-class="light" window-style="max-width: 350px;">
                                All RPC, read-only and validator nodes synced to Sonic Mainnet.
                            </f-info>
                        </h2>
                        <network-nodes-map />
                    </f-card>
                </div>
            </div>

            <div class="view-home_charts">
                <FCard>
                    <BlockTimestampAggregationsChart title="Transactions per second" :interval="11000" :decimals="1" />
                </FCard>
                <FCard>
                    <BlockTimestampAggregationsChart title="Gas used per second" subject="GAS_USED" :interval="12000" />
                </FCard>
                <FCard>
                    <TtfTimestampAggregationsChart
                        :interval="13000"
                        :decimals="2"
                        title="Time to finality"
                    />
                </FCard>
                <FCard>
                    <TransactionVolumes resolution="14d" />
                </FCard>
                <FCard>
                    <DailyBlocks resolution="14d" />
                </FCard>
                <FCard>
                    <DailyTxFees resolution="14d" />
                </FCard>
            </div>

            <div class="view-home_latest">
                <FCard>
                    <h2>
                        {{ $t('view_home.latest_blocks') }}
                        <router-link :to="{name: 'blocks'}">
                            All blocks
                        </router-link>
                    </h2>
                    <home-block-list :items="blocksData" :items-per-page="5" class="home-table" />
                </FCard>
                <FCard>
                    <h2>
                        {{ $t('view_home.latest_transactions') }}
                        <router-link :to="{name: 'transactions'}">
                            {{ $t('view_home.view_all_transactions') }}
                        </router-link>
                    </h2>
                    <home-transaction-list :items="blocksData" :items-per-page="5" class="home-table" />
                </FCard>
            </div>
<!--
            <div class="row row-2-cols-lg equal-height mat-5 view-home_latestblocks">
                <div class="col">
                    <div class="view-home_block">
                        <h2>
                            {{ $t('view_home.latest_blocks') }}
                            <router-link :to="{name: 'blocks'}">
                                All blocks
                            </router-link>
                        </h2>
                        <home-block-list :items="blocksData" :items-per-page="5" class="home-table" />
                    </div>
                </div>
                <div class="col">
                    <div class="view-home_block">
                        <h2>
                            {{ $t('view_home.latest_transactions') }}
                            <router-link :to="{name: 'transactions'}">
                                {{ $t('view_home.view_all_transactions') }}
                            </router-link>
                        </h2>
                        <home-transaction-list :items="blocksData" :items-per-page="5" class="home-table" />
                    </div>
                </div>
            </div>
-->

<!--
            <div class="row equal-height mat-5">
                <div class="col">
                    <TransactionVolumes resolution="3m" />
                </div>
            </div>

            <div v-if="showDailyBlocks" class="row mat-5">
                <div class="col">
                    <DailyBlocks resolution="3m" />
                </div>
            </div>

            <div v-if="showDailyTxFees" class="row mat-5">
                <div class="col">
                    <DailyTxFees resolution="3m" />
                </div>
            </div>
-->
<!--
            <div v-if="showFTMVault" class="row mat-5">
                <div class="col">
                    <h2 class="h3 home_h2">
                        {{ $t('view_home.vault') }}
                        <f-info show-on-hover button-tooltip="" window-class="light" window-style="max-width: 350px;">
                            The Ecosystem Vault collects <span class="number">10%</span> of all transaction fees paid to the network.
                            The next phase of the Vault is coming soon!
                            Stay tuned and meanwhile explore the successes of its most recent distribution winners.
                        </f-info>
                    </h2>
                    <FTMVault />
                </div>
            </div>
-->
<!--
            <div class="row mat-5">
                <div class="col">
                    <h2 class="h3 home_h2">
                        {{ $t('view_home.total_ftm_burned') }}
                        <f-info show-on-hover button-tooltip="" window-class="light" window-style="max-width: 350px;">
                            <span class="number">70%</span> of the fees paid to the network
                            are allocated to validators; <span class="number">15%</span> supports the <strong>GasM</strong> program,
                            <span class="number">10%</span> bolsters the <strong>Ecosystem Vault</strong>, and the remaining
                            <span class="number">5%</span> is permanently burnt.
                        </f-info>
                    </h2>
                    <BurnedFTM />
                </div>
            </div>
-->
        </div>
    </div>
</template>

<script>
    import FCard from "../components/core/FCard/FCard.vue";
    // import FSearchBox from "../components/FSearchBox.vue";
    import gql from 'graphql-tag';
    import HomeBlockList from "@/data-tables/HomeBlockList.vue";
    import HomeTransactionList from "@/data-tables/HomeTransactionList.vue";
    // import AnimatedNumber from "animated-number-vue";
    import {pollingMixin} from "@/mixins/polling.js";
    import TransactionVolumes from "@/components/TransactionVolumes.vue";
    import {formatNumberByLocale} from "@/filters.js";
    // import BurnedFTM from "@/components/BurnedFTM.vue";
    import appConfig from '../../app.config.js';
    import NetworkNodesMap from "@/components/NetworkNodesMap.vue";
    import FInfo from "@/components/core/FInfo/FInfo.vue";
    // import FTMVault from "@/components/FTMVault.vue";
    import DailyBlocks from "@/components/DailyBlocks.vue";
    import DailyTxFees from "@/components/DailyTxFees.vue";
    import ChainStats from "@/components/ChainStats.vue";
    import {mapState} from "vuex";
    import BlockTimestampAggregationsChart from "@/components/BlockTimestampAggregationsChart.vue";
    import TtfTimestampAggregationsChart
        from "@/components/TtfTimestampAggregationsChart.vue";
    // import AddChainButton from "@/components/AddChainButton.vue";

    export default {
        mixins: [pollingMixin],

        components: {
            // AddChainButton,
            TtfTimestampAggregationsChart,
            BlockTimestampAggregationsChart,
            ChainStats,
            DailyTxFees,
            DailyBlocks,
            // FTMVault,
            FInfo,
            NetworkNodesMap,
            // BurnedFTM,
            TransactionVolumes,
            HomeTransactionList,
            HomeBlockList,
            FCard,
            // FSearchBox,
            // AnimatedNumber
        },

        data() {
            return {
                blocksData: [],
                txVolumesResolution: '1m',
                txVolumesResolutions: [
                    {
                        label: '2 Weeks',
                        value: '14d',
                    },
                    {
                        label: '1 Month',
                        value: '1m',
                    },
                    {
                        label: '3 Months',
                        value: '3m',
                    },
                ],
                numAnimationDuration: 750,
                chainState: {
                    blocks: 0,
                    validators: 0,
                    accounts: 0,
                    transactions: 0,
                },
                showNetworkNodesMap: appConfig.flags.networkNodesMap,
                showFTMVault: appConfig.flags.ftmVault,
                showDailyBlocks: appConfig.flags.dailyBlocks,
                showDailyTxFees: appConfig.flags.dailyTxFees,
                appConfig,
                transactionsPerSecond: 0,
                transactionsPerDay: 0,
                gasPerSecond: 0,
            }
        },

        computed: {
            ...mapState(['lastTP10S', 'lastGP10S', 'lastTTFP10S'])
        },

        watch: {
            lastTP10S(serie) {
                // const items = toRaw(serie);
                const items = serie;

                if (items && items.length > 0) {
                    // const tps = Math.floor(items[items.length - 1].value);
                    const tps = this.getValuesPerSecond(items);

                    this.transactionsPerSecond = tps;
                    this.transactionsPerDay = 24 * 60 * 60 * tps;
                }
            },

            lastGP10S(serie) {
                // const items = toRaw(serie);
                const items = serie;

                if (items && items.length > 0) {
                    this.gasPerSecond = this.getValuesPerSecond(items);
                }
            }
        },


        created() {
            /*
                        watch(lastTTFP10S, (serie) => {
                            const items = toRaw(serie);

                            if (items?.length > 0) {
                                timeToFinality.value = getValuesPerSecond(items);
                            }
                        });
            */

            this.updateChainState();
        },

        mounted() {
            this._polling.start(
                'update-net-state',
                () => {
                    this.updateChainState();
                },
                5800
            );
        },

        methods: {
            getValuesPerSecond(serie) {
                const sum = serie.reduce((prev, curr) => prev + curr.value, 0);

                return sum / serie.length;
            },

            async updateChainState() {
                this.chainState = {...await this.fetchState2()};
            },

            /**
             * @returns {Promise<Object>}
             */
/*
            async fetchState() {
                const data = await this.$apollo.query({
                    query: gql`
                        query State {
                            state {
                                blocks
                                transactions
                                accounts
                                validators
                            }
                        }
                    `,
                    fetchPolicy: 'network-only',
                });

                return data.data.state || {};
            },
*/

            /**
             * @returns {Promise<Object>}
             */
            async fetchState2() {
                const data = await this.$apollo.query({
                    query: gql`
                        query State {
                state {
                    currentBlockHeight
                    numberOfAccounts
                    numberOfTransactions
                    numberOfValidators
                    diskSizePer100MTxs
                    diskSizePrunedPer100MTxs
                    timeToFinality
                    timeToBlock
                }
                        }
                    `,
                    client: 'sonic',
                    fetchPolicy: 'network-only',

                });

                return data.data.state || {};
            },

            formatNum(_num) {
                return formatNumberByLocale(parseInt(_num), 0);
            }
        }
    }
</script>

<style lang="scss">
    .view-home {
        margin-top: -16px;
        background: url('/pattern.svg') no-repeat;
        background-position-x: center;
        //background-position-y: var(--f-header-height);
        padding-top: 28px;

        overflow-x: hidden;

        &_header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 80px;
            //padding-top: 100px;
            margin-bottom: var(--f-spacer-9);

            > :first-child {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                //justify-content: center;
                gap: var(--f-spacer-7);
                padding-bottom: 140px;

                h1 {
                    font-size: var(--f-font-size-9);
                    font-weight: 500;
                    letter-spacing: var(--letter-spacing-3);
                    line-height: 1;
                    margin: 0;
                }

                h2 {
                    //font-size: var(--f-font-size-7);
                    font-weight: 500;
                    margin: 0;
                }
                //align-content: center;
            }
        }

        &_latest {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: var(--f-spacer-5);
            //margin-bottom: var(--f-spacer-9);

            h2 {
                display: flex;
                justify-content: space-between;

                a {
                    font-size: var(--f-font-size-5);
                    font-weight: normal;
                }
            }

            td {
                border: none;
            }
        }

        &_charts {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: var(--f-spacer-5);
            margin-bottom: var(--f-spacer-9);
            min-height: 371px;

            .flistbox {
                display: none;
            }

            h2 {
                font-size: var(--f-font-size-6);
            }
        }

        &_block {
            margin-bottom: -16px;

            h2 {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
                margin-bottom: 8px;

                a {
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }

        &_latestblocks {
           margin-bottom: 16px;
        }

        .home_h2 {
            margin-bottom: 12px;
        }

        .f-search-box {
            width: 100%;
            padding: 8px 0 40px 0;
            //padding: 64px 0;
        }

        .home-block {
            --f-card-padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;

            min-height: 195px;

            h2 {
                text-align: center;
                //margin-top: 16px;
                margin-bottom: 4px;
            }

            .num {
                text-align: center;
                font-weight: bold;
                font-size: 38px;
            }
        }

        .home-table {
            //margin-bottom: 16px;
            //height: 300px;
        }

        .f-info {
            margin-top: -2px;
            margin-inline-start: 8px;
        }
    }

    @media (max-width: 1150px) {
        .view-home {
            padding-top: 17px;

            &_header {
                display: grid;
                grid-template-columns: 1fr;
                gap: 40px;
                //padding-top: 50px;

                > :first-child {
                    order: 1;
                    padding-bottom: 0;
                }

                > :last-child {
                    order: 0;
                }
            }

            &_latest, &_charts {
                grid-template-columns: 1fr;
            }

            .chainstats {
                h2 {
                    font-size: var(--f-font-size-6);
                }

                .chainstatsrow {
                    &_title {
                        font-size: var(--f-font-size-5b);
                    }
                }
            }
        }
    }

    @include media-max(1150px) {
        .view-home {
            &_latestblocks {
                flex-direction: column;

                > .col {
                    max-width: 100%;
                }
            }
        }
    }

    @include media-max($bp-menu) {
        .view-home {
            .f-search-box {
                padding: 32px 0;
            }
        }
    }

    @include media-max($bp-small) {
        .view-home {
            &_block {
                h2 {
                    margin-bottom: 8px;
                }
            }

            .home-block {
                min-height: 136px;

                h2 {
                    font-size: $fs16;
                }

                .num {
                    font-size: 28px;
                }
            }

            .txvolumes_label {
                //font-size: 14px;
                h2 {
                    margin-bottom: 8px;
                }
            }
        }
    }
</style>
