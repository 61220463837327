const appConfig = {
    // app title
    name: "Sonic Explorer",
    // app description
    description:
        "Sonic Explorer allows you to explore and search the Sonic blockchain for transactions, addresses and blocks",
    // app keywords
    keywords: "sonic, explorer, search, blockchain, crypto, currency",
    image: 'https://sonic-explorer.skavel.cz/sonic-explorer.jpg',
    // apollo client settings
    apollo: {
        // list of providers. if one of them is unavailable, another is randomly picked
        providers: [
            {
                http: 'https://xapi.sonic.soniclabs.com/',
                // for subscriptions
                ws: '',
            },
        ],
        sonicApiProviders: [
            {
                http: 'https://api.sonic.soniclabs.com/',
                // for subscriptions
                ws: '',
            },
        ],
        // index into providers array of default provider or 'random' - takes index randomly
        defaultProviderIndex: "random"
    },
    //
    useTestnet: false,
    // use 'hash' mode in vue router
    routerHashMode: false,
    // testnet config
    testnet: {
        // list of providers. if one of them is unavailable, another is randomly picked
        providers: [
            {
                http: 'https://xapi.testnet.fantom.network/api',
                // for subscriptions
                ws: '',
            },
        ],
    },
    // progressive web application
    usePWA: true,
    // pwa settings
    pwa: {
        // name used in pwa manifest
        name: "Sonic Explorer"
    },
    // default options for production build
    build: {
        // output dir for production build
        outputDir: "dist"
    },
    // downtime threshold in seconds. downtime values less than this threshold will be displayed as 0
    downtimeThreshold: 10,
    // feature flags
    flags: {
        sonicBlaze: false,
        networkNodesMap: false,
        rewardsEstimation: false,
        ftmVault: true,
        dailyBlocks: true,
        dailyTxFees: true,
    },
};

if (appConfig.useTestnet) {
    appConfig.apollo.providers = appConfig.testnet.providers;
}

if (appConfig.flags.sonicBlaze) {
    appConfig.name = 'Sonic Blaze Testnet';
    appConfig.pwa.name = 'Sonic Blaze Testnet';
    appConfig.image = 'https://sonic-blaze-explorer.skavel.cz/sonic-blaze-explorer.jpg';
    appConfig.apollo.providers = [
        {
            http: 'https://xapi.blaze.soniclabs.com/',
            // for subscriptions
            ws: '',
        },
    ];
    appConfig.apollo.sonicApiProviders = [
        {
            http: 'https://api.blaze.soniclabs.com/',
            // for subscriptions
            ws: '',
        },
    ];
}

// scss variables prepended to every scss file
appConfig.scssData = `
    @import "src/assets/scss/vars";
`;

module.exports = appConfig;
