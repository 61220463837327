<script>
import {toInt} from "@/utils/big-number";
import {formatNumberByLocale} from "@/filters";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "ChainStatsRow",

    components: { AnimatedNumber },

    props: {
        number: {
            type: [Number, String],
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        popoverText: {
            type: String,
            default: '',
        },
        animate: {
            type: Boolean,
            default: false,
        },
        useBlur: {
            type: Boolean,
            default: false,
        },
        useShortNumberFormat: {
            type: Boolean,
            default: false,
        },
        displayAsIs: {
            type: Boolean,
            default: false,
        },
        precision: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: '',
        },
        unit: {
            type: String,
            default: '',
        },
        linkTo: {
            type: Object,
            default() {
                return null;
            },
        },
    },

    data() {
        return {
            numberClasses: {}
        }
    },

   computed: {
        cNumber() {
            let num = toInt(this.number);
            let fNum = '';

            if (this.displayAsIs) {
                num = this.number;
            } /*else if (this.animate) {
                num = Math.floor(animatedNumber.value);
            }*/

            if (!isNaN(num)) {
                fNum = num;
/*
                if (this.unit) {
                    fNum = handleNumberWithUnit(num);
                } else if (this.useShortNumberFormat) {
                    fNum = formatters.number(num, this.precision, 'compact');
                } else {
                    fNum = formatters.number(num);
                }
*/
            }

            return fNum;
       },

       unitS() {
            return this.unit === 'second' ? 's' : '';
       }
   },

    methods: {
        formatNum(_num) {
            return formatNumberByLocale(parseInt(_num), 0);
        },

        onBeging() {
            if (this.useBlur) {
                this.numberClasses = { blur: true };
            }
        },

        onComplete() {
            if (this.useBlur) {
                this.numberClasses = { blur: false };
            }
        }
    }


}
</script>

<template>
    <div class="chainstatsrow">
        <h3 class="chainstatsrow_title">
            <slot name="title" v-bind="{ title }">
                <span class="chainstatsrow_title_text" v-html="title"></span>
<!--
                <InfoPopover
                    v-if="popoverText"
                    :button-title="
                        $t('app.numberBlock.moreInfoAbout', { text: title })
                    "
                    class="chainstatsrow_infopopover"
                >
                    <div v-html="popoverText"></div>
                </InfoPopover>
-->
            </slot>
        </h3>
        <span :class="numberClasses" data-testcode="chainstatsrow_number">
            <slot v-bind="{ number: cNumber }">
                <template v-if="prefix">{{ prefix }} </template>
                <animated-number
                    v-if="animate"
                    :value="cNumber"
                    :formatValue="formatNum"
                    :duration="750"
                    :begin="onBeging"
                    :complete="onComplete"
                />
                <template v-else>{{ cNumber }}</template>
                <span v-if="unit" class="chainstatsrow_unit">{{
                    unitS
                }}</span>
            </slot>
        </span>
        <RouterLink
            v-if="linkTo"
            :to="linkTo"
            class="chainstatsrow_link noeffect"
        />
    </div>
</template>

<style scoped lang="scss">
.chainstatsrow {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: var(--letter-spacing-1);
    font-size: var(--f-font-size-6);
    font-weight: 600;

    &_title {
        font-size: var(--f-font-size-6);
        font-weight: 600;
        margin: 0;
    }

    &_link {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &_unit {
        font-size: 96%;
        //opacity: var(--f-opacity-5);
        padding-inline-start: var(--f-spacer-2);
    }

    /*
    &_infopopover {
        h4 {
            font-size: 18px;
            font-weight: normal;
            letter-spacing: initial;
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            margin-top: var(--f-spacer-3);
            padding-top: var(--f-spacer-3);
            opacity: 0.83;
            border-top: 1px solid var(--f-color-secondary-3);
        }
    }

    .infopopover {
        position: relative;
        z-index: var(--f-zindex-above);
    }
    */
}
</style>
