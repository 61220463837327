<script>
import ChainStatsRow from "@/components/ChainStatsRow.vue";
import FCard from "@/components/core/FCard/FCard.vue";

export default {
    name: "ChainStats",

    components: {
        ChainStatsRow, FCard
    },

    props: {
        blocks: {
            type: [Number, String],
            default: '',
        },
        transactions: {
            type: [Number, String],
            default: '',
        },
        validators: {
            type: [Number, String],
            default: '',
        },
        accounts: {
            type: [Number, String],
            default: '',
        },
        timeToBlock: {
            type: Number,
            default: 0,
        },
        transactionsPerSecond: {
            type: [Number, String],
            default: '',
        },
        timeToFinality: {
            type: Number,
            default: 0,
        },
        gasPerSecond: {
            type: [Number, String],
            default: '',
        },
        transactionsPerDay: {
            type: [Number, String],
            default: '',
        },
    }
}
</script>

<template>
    <FCard class="chainstats">
        <h2>Stats</h2>
        <ChainStatsRow
            :number="blocks"
            title="Blocks <span class='basicchaininfo_arrow'>&rsaquo;</span>"
            :link-to="{ name: 'blocks' }"
            animate
            use-blur
            data-testid="num_of_blocks"
            class="chainstats_hovereffect"
        />
<!--
        <ChainStatsRow
            :number="timeToBlock"
            unit="second"
            :precision="2"
            display-as-is
            title="Average block time"
        />
-->
        <ChainStatsRow
            :number="transactions"
            title="Transactions <span class='basicchaininfo_arrow'>&rsaquo;</span>"
            prefix="~"
            :link-to="{ name: 'transactions' }"
            animate
            use-blur
            data-testid="num_of_transactions"
            class="chainstats_hovereffect"
        />
        <ChainStatsRow
            :number="transactionsPerSecond"
            title="Transactions per second"
            animate
            use-blur
        />
        <ChainStatsRow
            :number="transactionsPerDay"
            title="Transactions per day"
            animate
            use-blur
            class="basicchaininfo_tpd"
        />
        <ChainStatsRow
            :number="timeToFinality"
            unit="second"
            :precision="2"
            display-as-is
            title="Time to finality"
        />
        <ChainStatsRow
            :number="gasPerSecond"
            title="Gas per second"
            animate
            use-blur
        />
        <ChainStatsRow
            :number="validators"
            title="Validators <span class='basicchaininfo_arrow'>&rsaquo;</span>"
            :link-to="{ name: 'staking' }"
            animate
            use-blur
            data-testid="num_of_blocks"
            class="chainstats_hovereffect"
        />
        <ChainStatsRow
            :number="accounts"
            title="Accounts"
            animate
            use-blur
            data-testid="num_of_blocks"
        />
    </FCard>
</template>

<style scoped lang="scss">
.chainstats {
    h2 {
        //font-size: var(--f-font-size-7);
        //font-weight: 700;
        padding: 0 var(--f-spacer-3) var(--f-spacer-4) var(--f-spacer-3);
        margin: 0;
    }

    .chainstatsrow {
        padding: var(--f-spacer-4) var(--f-spacer-3);

        & + .chainstatsrow {
            border-top: 1px solid var(--dark-theme-color3b);
        }
    }

    &_hovereffect.chainstatsrow {
        transition: all var(--f-transition-length) var(--f-transition-func);

        &:hover {
            //transform: scale(1.01);

            &.chainstatsrow {
                background-color: var(--dark-theme-color3b);
                //background-color: var(--theme-dark-color-6);
            }
        }
    }
}

</style>
