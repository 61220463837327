<script>
import FLightweightCharts from "@/components/core/FLightweightCharts/FLightweightCharts.vue";
import gql from "graphql-tag";
import {formatNumberByLocale} from "../filters";
import {pollingMixin} from "@/mixins/polling";

export default {
    name: "TtfTimestampAggregationsChart",

    mixins: [pollingMixin],

    components: {FLightweightCharts},

    props: {
        title: {
            type: String,
            default: ''
        },
        interval: {
            type: Number,
            default: 10010,
        },
        decimals: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            series: [],
            num: 0
        }
    },

    mounted() {
        setTimeout(() => {
            this.update(true);
        }, 1050);

        this._polling.start(
            'time-to-finality',
            () => { this.update() },
            this.interval
        );
    },

    methods: {
        async update(firstUpdate) {
            const series = await this.fetchTtfTimestampAggregations();
            const serie = this.transformSeries(series);
            const bar = serie[serie.length - 1];

            if (bar) {
                this.num = bar.value;
            }

            this.$store.commit('updateLastTTFP10S', serie);

            if (!firstUpdate) {
                if (this.$refs.chart) {
                    this.$refs.chart.addBar(bar);
                }
            } else {
                this.series = serie;
            }
        },

        async fetchTtfTimestampAggregations() {
            const data = await this.$apollo.query({
                query: gql`
            query GetTtfTimestampAggregations {
                ttfTimestampAggregations {
                    timestamp
                    value
                }
            }
                `,
                fetchPolicy: 'network-only',
                client: 'sonic'
            });

            return data.data.ttfTimestampAggregations || [];
        },

        transformSeries(series) {
            return series.map((item) => {
                let value = item.value;

                /*
                if (convertToSeconds) {
                    value = value / 10;
                }
        */

                return {
                    time: item.timestamp,
                    value,
                };
            });
        },

        formatNumberByLocale,
    }
}
</script>

<template>
    <div class="dailyblocks">
        <div class="chart_label">
            <h2 id="dailyblocks_l">{{ title }}</h2>
            <div class="chart_num">{{ formatNumberByLocale(num, decimals) }}</div>
        </div>
        <FLightweightCharts
            ref="chart"
            :series="series"
            series-type="area"
            :fit-content="true"
            :height="212"
            :price-scale-min="0"
            :price-scale-max="'auto'"
            :options="{
                handleScroll: false,
                handleScale: false,
                // timeScale: { visible: false },
                timeScale: { timeVisible: true },
            }"
            :series-options="{
                priceLineVisible: false,
                priceFormat: { type: 'volume', precision: 2, minMove: 1 },
                priceScale: {
                    scaleMargins: {
                        top: 0.05,
                        bottom: 0.02,
                    },
                },
            }"
        />
    </div>
</template>
