<template>
    <footer class="footer">
        <div class="footer_in">
            <div class="footer_start">
                <SocialMediaLinks />
                <img src="/sonic-logo-white.svg" alt="Sonic logo" height="28" class="footer_logo" />
                <div class="footer_copyright">© {{ year }} Sonic Labs. All rights reserved.</div>
            </div>
            <div class="footer_end">
                <div class="footer_links">
                    <h4>External</h4>
                    <ul class="no-markers">
                        <li>
                            <a href="https://www.soniclabs.com/" target="_blank">Website</a>
                        </li>
                        <li>
                            <a href="https://blog.soniclabs.com/" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a href="https://docs.soniclabs.com/" target="_blank">Docs</a>
                        </li>
                        <li>
                            <a
                                href="https://fantomfoundation.notion.site/4518775d6a7c4b7685e43628431358ab?v=6a6b5746a3204e34ad75e28ea68ac986"
                                target="_blank"
                            >Media Kit</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="footer_links">
                    <h4>Legal</h4>
                    <ul class="no-markers">
                        <li>
                            <a
                                href="https://fantomfoundation.notion.site/Sonic-Website-Term-and-Conditions-87e482091a6443d3ab7476cfa751b977"
                                target="_blank"
                            >Terms and Conditions</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://fantomfoundation.notion.site/Sonic-Privacy-Policy-9582d39482584ec2a2c04078dad04518"
                                target="_blank"
                            >Privacy Policy</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import SocialMediaLinks from "@/components/SocialMediaLinks.vue";

    export default {
        components: {
            SocialMediaLinks,
        },

        data() {
            return {
                year: new Date().getFullYear(),
            };
        },
    }
</script>

<style lang="scss">
.footer {
    --f-footer-link-color: var(--f-primary-color);

    /*color: #fff;*/
    /*color: #fff;*/
    /*background-color: var(--f-primary-color);*/
    /*
    min-height: 64px;

    .narrow-container {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 100%;

        > .row {
            height: 100%;
        }
    }

    @include links() {
        color: var(--f-footer-link-color);
        opacity: 1;
        transition: opacity $transition-length ease;
    }

    a:not(.btn):hover {
        opacity: 0.5;
        text-decoration: none;
    }
    */

    margin-top: var(--f-spacer-9);
    //background: var(--theme-dark-color-9);
    color: var(--f-doc-color);
    border-top: 1px solid var(--dark-theme-color3b);

    &_in {
        display: flex;
        justify-content: space-between;
        padding: var(--f-spacer-7);
        border-top: 1px solid var(--f-color-secondary);
        max-width: calc(1250px + 2 * var(--f-spacer-4));
        margin: 0 auto;
    }

    &_start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        //gap: var(--f-spacer-6);
    }

    &_end {
        display: flex;
        gap: var(--f-spacer-9);
    }

    &_links {
        h4 {
            margin: var(--f-spacer-2) 0 var(--f-spacer-5) 0;
            color: #ffecd4;
            font-weight: 500;
            font-size: 18px;
        }

        ul {
            list-style-type: none;
        }

        li + li {
            padding-top: var(--f-spacer-4);
        }

        a:visited:not(.btn),
        a:link:not(.btn) {
            color: var(--f-doc-color) !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &_logo {
        margin-bottom: 0;
    }

    &_copyright {
    }
}

.view-transition-on {
    .f-footer {
        visibility: hidden;
    }
}

@media (max-width: 1075px) {
    .footer {
        &_in {
            flex-direction: column;
            gap: var(--f-spacer-5);
        }

        &_start {
            gap: var(--f-spacer-5);
            order: 1;
        }

        &_end {
            order: 2;
        }

        .socialmedialinks {
            .btn {
                width: 64px;
                height: 64px;
            }
        }
    }
}

@media (max-width: 710px) {
    .footer {
        .socialmedialinks {
            flex-wrap: wrap;
        }
    }
}
</style>
